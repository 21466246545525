import React from 'react';

function HeroSection({ backgroundImage, title, subtitle, buttonText }) {
  return (
    <section 
      className="hero-section"
      style={{ 
        backgroundImage: `url(${backgroundImage || process.env.PUBLIC_URL + '/DSCI0039.JPG'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff'
      }}
    >
      <div className="hero-text">
        <h2>{title || 'Revolutionizing AI Solutions'}</h2>
        <h1>{subtitle || 'Empowering businesses with tailored AI and machine learning tools.'}</h1>
        <button className="hero-button">{buttonText || 'Learn More'}</button>
      </div>
    </section>
  );
}

export default HeroSection;