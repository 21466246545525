import React from 'react';
import './Features.css';

function Features() {
  return (
    <section className="features">
      <h2>Our Services</h2>
      <div className="feature-list">
        <div className="feature-item">
          <h3>Custom AI Models</h3>
          <p>We build custom AI solutions to solve your unique business challenges.</p>
        </div>
        <div className="feature-item">
          <h3>Data Analytics</h3>
          <p>Leverage data to gain insights and make informed decisions.</p>
        </div>
        <div className="feature-item">
          <h3>Consulting Services</h3>
          <p>Get expert advice to integrate AI seamlessly into your workflow.</p>
        </div>
      </div>
    </section>
  );
}

export default Features;