import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 MLCraft. All Rights Reserved. CVR-Number: 43751514</p>
      <p>Contact us at contact@mlcraft.dk</p>
    </footer>
  );
}

export default Footer;