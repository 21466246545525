import React from 'react';
import HeroSection from '../components/HeroSection';
import './Services.css'; // Add this to style the page-specific content

function Services() {
  return (
    <div>
      {/* Customized HeroSection for the Services page */}
      <HeroSection 
        backgroundImage={`${process.env.PUBLIC_URL}/Speaker.png`} 
        title="Our Expert Services"
        subtitle="Delivering customized solutions to meet your AI needs."
        buttonText="Explore Services"
      />

      {/* New content for the Services page */}
      <div className="services-content">
        <h2>What We Offer</h2>
        <div className="services-details">
          <div className="service-item">
            <h3>AI Consulting</h3>
            <p>Our experts guide you through adopting and implementing AI in your business processes.</p>
          </div>
          <div className="service-item">
            <h3>Custom Model Development</h3>
            <p>We create AI models tailored specifically to solve your unique business challenges.</p>
          </div>
          <div className="service-item">
            <h3>Data Analysis Services</h3>
            <p>Gain actionable insights from your data with our comprehensive data analysis solutions.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;